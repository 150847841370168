var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _vm.main
      ? _c("h1", { staticClass: "page-title__header" }, [_vm._t("default")], 2)
      : _c("h2", { staticClass: "page-title__header" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }