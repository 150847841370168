<template>
  <div :class="classes">
    <h1 v-if="main" class="page-title__header">
      <slot />
    </h1>
    <h2 v-else class="page-title__header">
      <slot />
    </h2>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  components: {},
  props: {
    main: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "card-news": true,
        "page-title_white": this.white,
        "no-margin": this.noMargin,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  &__header {
    font-family: $font-family-bebas;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 30px;
    line-height: 120%;
    letter-spacing: 0.05em;
    font-size: 30px;
    @media screen and (min-width: $screen-sm) {
      line-height: 130%;
      font-size: 48px;
    }
  }
}
.no-margin {
  .page-title__header {
    margin: 0;
  }
}
.page-title_white {
  .page-title__header {
    color: #fff;
  }
}
</style>
